import React from "react"
import { Link } from "gatsby"
import Navigation from "../components/navigation"
import Logo from '../../static/assets/logo.svg';
import 'prismjs/themes/prism-okaidia.css';

export default ({ children }) => {
  return (
    <div className="site-wrapper">
      <header className="site-header">
        <div className="site-title">
          <Link to="/"><Logo /></Link>
        </div>
        <Navigation />
      </header>
      {children}
      <footer className="site-footer">
        <p>&copy; {new Date().getFullYear()} UseUrWings.com</p>
      </footer>
    </div>
  )
}
